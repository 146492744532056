
import { defineComponent, ref, toRefs } from "vue";
import Axios from "@/core/services/Http";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { Notify } from "@/core/services";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "Customers",
	components: {
		InnerLoader,
	},
	props: {
		country_id: String,
	},
	setup(props) {
		const modal: any = ref();
		const store = useStore();
		const i18n = useI18n();
		const reports = ref([]) as Record<any, any>;
		const { country_id } = toRefs(props);
		const loading = ref(false);

		const getCustomers = () => {
			if (country_id?.value == "") {
				Notify.error(`${i18n.t("message.COUNTRY")} ${i18n.t("message.IS_REQUIRED")}`);
				return;
			}
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_SYNC_CUSTOMERS")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loading.value = true;
					try {
						const economic_customers = async (page) => {
							const response = await Axios.get(`/economic/get-customers/${country_id?.value}/${page}`);
							if (response.data.report.length > 0) reports.value.push(response.data.report);
							if (response.data?.next_page != "" && response.data?.next_page > 0) {
								await economic_customers(response.data?.next_page);
							}
						};
						await economic_customers(0);
						if (reports.value.length > 0) {
							modal.value = new Modal(document.getElementById("show_report")) as HTMLElement;
							modal.value.show();
							Notify.error(i18n.t("message.SOMETHING_WENT_WRONG"));
							loading.value = false;
						} else {
							Notify.success(i18n.t("message.CUSTOMERS_SYNC_SUCCESSFULLY"));
							loading.value = false;
						}
					} catch ($e) {
						Notify.error($e);
						loading.value = false;
					}
				})
				.catch(() => {
					loading.value = false;
				});
		};

		return {
			loading,
			reports,
			getCustomers,
		};
	},
});
