
import { defineComponent, toRefs, ref } from "vue";
import Axios from "@/core/services/Http";
import { useStore } from "vuex";
import { Notify } from "@/core/services";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "languages",
	components: {
		InnerLoader,
	},
	props: {
		country_id: String,
	},
	setup(props) {
		const store = useStore();
		const { country_id } = toRefs(props);
		const i18n = useI18n();
		const loading = ref(false);

		const getPriceGroups = () => {
			if (country_id?.value == "") {
				Notify.error(`${i18n.t("message.COUNTRY")} ${i18n.t("message.IS_REQUIRED")}`);
				return;
			}
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_SYNC_PRICE_GROUPS")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loading.value = true;
					try {
						await Axios.get("/economic/get-price-groups/" + country_id?.value);
						Notify.success(i18n.t("message.PRICE_GROUPS_SYNC_SUCCESSFULLY"));
						loading.value = false;
					} catch ($e) {
						Notify.error($e);
						loading.value = false;
					}
				})
				.catch(() => {
					loading.value = false;
				});
		};

		return {
			loading,
			getPriceGroups,
		};
	},
});
