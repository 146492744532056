
import { defineComponent, ref, toRefs } from "vue";
import Axios from "@/core/services/Http";
import { useStore } from "vuex";
import { Notify } from "@/core/services";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "languages",
	components: {
		InnerLoader,
	},
	props: {
		country_id: String,
	},
	setup(props) {
		const store = useStore();
		const i18n = useI18n();
		const reports = ref([]) as Record<any, any>;
		const { country_id } = toRefs(props);
		const loading = ref(false);

		const getProducts = () => {
			if (country_id?.value == "") {
				Notify.error(`${i18n.t("message.COUNTRY")} ${i18n.t("message.IS_REQUIRED")}`);
				return;
			}
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_SYNC_PRODUCTS")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loading.value = true;
					try {
						const economic_products = async (page) => {
							const response = await Axios.get(`/economic/get-products/${country_id?.value}/${page}`);
							if (response.data?.next_page != "" && response.data?.next_page > 0) {
								await economic_products(response.data?.next_page);
							}
						};
						await economic_products(0);
						Notify.success(i18n.t("message.PRODUCTS_SYNC_SUCCESSFULLY"));
						loading.value = false;
					} catch ($e) {
						Notify.error($e);
						loading.value = false;
					}
				})
				.catch(() => {
					loading.value = false;
				});
		};

		return {
			reports,
			loading,
			getProducts,
		};
	},
});
