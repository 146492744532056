
import { defineComponent, toRefs, ref } from "vue";
import Axios from "@/core/services/Http";
import { Notify } from "@/core/services";
import { Modal } from "bootstrap";
import { Input } from "@/components/input-elements";
import { AddImportForm } from "../../interfaces";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "Import Price Group",
	props: {
		country_id: {
			type: String,
			default: "",
		},
	},
	components: {
		Input,
		InnerLoader,
	},
	setup(props) {
		const modal: any = ref();
		const i18n = useI18n();
		const { country_id } = toRefs(props);
		const showImportInputs = ref(false);
		const inputFile = ref<null | HTMLFormElement>(null);
		const fileName = ref("");
		let keyFile = ref([]) as Record<any, any>;
		const loading = ref(false);
		const addImportForm = ref<null | HTMLFormElement>(null);

		// Given Add Price Group Form Interface
		const priceGroupData = ref<AddImportForm>({
			priceGroupId: "",
		});

		const triggerFile = () => {
			inputFile.value?.click();
		};

		const handleFileChange = (event: Record<any, any>) => {
			keyFile = event.target.files;
			fileName.value = event.target.files[0].name;

			if (
				event.target.files[0].type == "application/wps-office.xlsx" ||
				event.target.files[0].type == "application/wps-office.xls" ||
				event.target.files[0].type == "application/vnd.ms-excel" ||
				event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			) {
				return true;
			} else {
				keyFile = [];
				fileName.value = "";
				Notify.error(i18n.t("message.UPLOAD_EXCEL_FILE"));
			}
		};

		const importPriceGroups = () => {
			modal.value = new Modal(document.getElementById("modal_add_language")) as HTMLElement;
			priceGroupData.value.priceGroupId = "";
			fileName.value = "";
			modal.value.show();
		};

		const submitImportForm = async () => {
			if (country_id?.value == "") {
				Notify.error(`${i18n.t("message.COUNTRY")} ${i18n.t("message.IS_REQUIRED")}`);
				return;
			}

			addImportForm.value?.validate(async (valid: boolean) => {
				if (keyFile[0] == "" || keyFile[0] == undefined) {
					Notify.error(`${i18n.t("message.FILE")} ${i18n.t("message.IS_REQUIRED")}`);
					return;
				}

				if (valid) {
					const myFile = keyFile[0];
					const form = new FormData();
					form.append("File", myFile);
					form.append("price_group_id", priceGroupData.value.priceGroupId);
					form.append("country_id", country_id?.value);

					loading.value = true;
					try {
						await Axios.post("/economic/import-price-groups/", form, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						});
						Notify.success(i18n.t("message.PRICE_GROUPS_SYNC_SUCCESSFULLY"));
						loading.value = false;
						modal.value.hide();
					} catch ($e) {
						Notify.error($e);
						loading.value = false;
					}
				}
			});
		};

		return {
			showImportInputs,
			inputFile,
			fileName,
			priceGroupData,
			addImportForm,
			loading,
			triggerFile,
			handleFileChange,
			submitImportForm,
			importPriceGroups,
		};
	},
});
